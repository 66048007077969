import React from "react"

import "./index.scss"
import "../global-project-styles.scss"

import useExitAnimationFade from "../../../animations/useExitAnimationFade"

import ProjectHero from "../ProjectHero"
import useProjectImagesParallax from "../../../animations/useParallaxItems"
import useProjectRevealAnimations from "../../../animations/useProjectRevealAnimations"
import RepeatableSection from "../RepeatableSection"

import { cliftonData } from "./cliftonData"
import ProjectEndContent from "../ProjectEndContent"
import SEO from "../../seo"

const Clifton = ({ data: pageData }) => {
  useExitAnimationFade()
  useProjectImagesParallax()
  useProjectRevealAnimations()

  console.log("pageData", pageData)

  return (
    <div className="page project clifton">
      <SEO title="Cilfton" />
      <ProjectHero
        pageData={pageData}
        heroImg="exterior3After"
        title="clifton"
        description="Coastal luxe home with direct inspiration from it's stunning outlook."
      />
      {cliftonData.map((sectionData, index) => (
        <RepeatableSection
          pageData={pageData}
          key={index}
          section={sectionData}
        />
      ))}
      <ProjectEndContent to="/sunvale/" />
    </div>
  )
}

export default Clifton
