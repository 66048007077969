export const cliftonData = [
  {
    name: "intro",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "",
        text: `The ultimate coastal luxe! Opulent and luxurious, this home boasts five-stars with picture perfect views as you can soak up a spectacular sunrise day after day.`,
      },
      { type: "after", size: "md", afterImg: "diningAfter" },
      { type: "after", size: "sm", afterImg: "bathroomAfter" },
      { type: "after", size: "md", afterImg: "theatreAfter" },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "entryBefore",
        beforeImgStyle: {},
        afterImg: "entryAfter",
        afterStyle: {},
      },
      {
        type: "content-text",
        title: "",
        text: `
          By removing a wall that used to create a 'rabbit warren' feeling, we were able to capture the stunning views from the moment you step foot in the door.`,
      },
      { type: "after", size: "sm", afterImg: "plants" },
      {
        type: "content-text",
        title: "",
        text: ` With whitewash timber, beams and hanging plant feature to separate the entry.`,
      },
      { type: "after", size: "lg", afterImg: "ensuiteAfter" },
    ],
  },
  {
    name: "exterior",
    textColumn: "right",
    col1: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "exterior1Before",
        afterImg: "exterior1After",
        afterStyle: {},
      },
      {
        type: "comparison",
        size: "sm",
        beforeImg: "exterior2Before",
        beforeImgStyle: { objectFit: "cover" },
        afterImg: "exterior2After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "text",
        title: "Exterior",
        text: `A complete new plaster system provides low maintenance, great weathertightness and topped off with our favourite 'white' and paired with existing cedar shingle roof, gives this home the luxurious look it deserves. <br/><br/> Not to mention all the new frameless glass balustrades to enhance the uninterrupted views.`,
      },
      { type: "after", size: "lg", afterImg: "exterior3After" },
    ],
  },
  {
    name: "lounge",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Lounge",
        text:
          "By adding gas fires with television recesses to both lounges, gave the homely feel to both lounge rooms. Both flowing to the dining rooms and their own decks - all capturing the ever-changing views.",
      },
      { type: "after", size: "md", afterImg: "lounge3After" },
      { type: "after", size: "lg", afterImg: "lounge4After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "lounge2After" },
      { type: "after", size: "sm", afterImg: "lounge1After" },
    ],
  },
  {
    name: "kitchen",
    textColumn: "right",
    col1: [
      { type: "after", size: "md", afterImg: "kitchen3After" },
      { type: "after", size: "sm", afterImg: "kitchen2After" },
    ],
    col2: [
      {
        type: "text",
        title: "Kitchen",
        text:
          "To get the coastal look in the kitchen, I was set on using rattan and oak. After plenty of searching, we found a local joiner who was able to create exactly what we were looking for! we are so happy we took the risk because it certainly paid off.",
      },
      { type: "after", size: "lg", afterImg: "kitchen1After" },
    ],
  },
  {
    name: "bedrooms",
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bedrooms",
        text:
          "Five large bedrooms all with wardrobes and their own balconies.  They provide the perfect view of the sunrise over the pacific ocean making it hard to justify leaving your bedroom!",
      },
      { type: "after", size: "lg", afterImg: "bedroom1After" },
      { type: "after", size: "sm", afterImg: "bedroom3After" },
      { type: "after", size: "md", afterImg: "bedroom5After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "bedroom2After" },
      { type: "after", size: "md", afterImg: "bedroom4After" },
      { type: "after", size: "sm", afterImg: "bedroom6After" },
    ],
  },
]
