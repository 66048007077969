import { graphql } from "gatsby"
import CliftonPage from "../components/projects/clifton"

export const query = graphql`
  query {
    kitchenAfter: file(
      relativePath: { eq: "projects/clifton/kitchen-after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    entryAfter: file(relativePath: { eq: "projects/clifton/entry-after.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    entryBefore: file(
      relativePath: { eq: "projects/clifton/entry-before.JPEG" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bathroomAfter: file(
      relativePath: { eq: "projects/clifton/bathroom-after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    diningAfter: file(
      relativePath: { eq: "projects/clifton/dining-after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ensuiteAfter: file(
      relativePath: { eq: "projects/clifton/ensuite-after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    theatreAfter: file(
      relativePath: { eq: "projects/clifton/theatre-after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge1After: file(
      relativePath: { eq: "projects/clifton/lounge 1 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge2After: file(
      relativePath: { eq: "projects/clifton/lounge 1.5 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lounge3After: file(
      relativePath: { eq: "projects/clifton/lounge 2 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen1After: file(
      relativePath: { eq: "projects/clifton/kitchen 1 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen2After: file(
      relativePath: { eq: "projects/clifton/kitchen 1.5 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen3After: file(
      relativePath: { eq: "projects/clifton/kitchen 2 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior1Before: file(
      relativePath: { eq: "projects/clifton/exterior 1 before.JPEG" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior1After: file(
      relativePath: { eq: "projects/clifton/exterior 1 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior2After: file(
      relativePath: { eq: "projects/clifton/pool 1.5 after.JPEG" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior2Before: file(
      relativePath: { eq: "projects/clifton/pool before.JPEG" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior3After: file(
      relativePath: { eq: "projects/clifton/exterior after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior4After: file(
      relativePath: { eq: "projects/clifton/exterior 2 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    lounge4After: file(
      relativePath: { eq: "projects/clifton/lounge 3 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    plants: file(relativePath: { eq: "projects/clifton/plants.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom1After: file(
      relativePath: { eq: "projects/clifton/bedroom1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom2After: file(
      relativePath: { eq: "projects/clifton/bedroom2After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom3After: file(
      relativePath: { eq: "projects/clifton/bedroom3After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom4After: file(
      relativePath: { eq: "projects/clifton/bedroom4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom5After: file(
      relativePath: { eq: "projects/clifton/bedroom5After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom6After: file(
      relativePath: { eq: "projects/clifton/bedroom6After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CliftonPage
